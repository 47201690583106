export const CONTACT_INFO = {
	phone: '+91-9868629924',
	email: 'enquiry@mtalkz.com',
	address: 'Gurugram, India',
	social: {
		facebook: 'https://facebook.com/mtalkz',
		twitter: 'https://twitter.com/mtalkz',
		linkedin: 'https://linkedin.com/company/mtalkz',
	},
};

export const COMPANY_INFO = {
	name: 'Mtalkz',
	logo: 'https://rvaomqrjmsbzyxroaffz.supabase.co/storage/v1/object/public/strapi/logo_Mtalkz_side_view_f3b2602c47.png',
	logoAlt: 'Mtalkz Logo',
};

export const COMPANY_ADDRESS = {
	name: 'Mtalkz Mobility Services (P) Ltd.',
	address: ['C-2, Sector-1 Noida,', 'UP - 201301'],
};

export const SOCIAL_LINKS = [
	{ name: 'Facebook', href: 'https://facebook.com/mtalkz', icon: 'Facebook' },
	{
		name: 'LinkedIn',
		href: 'https://in.linkedin.com/company/mtalkzmobility/',
		icon: 'Linkedin',
	},
	{
		name: 'WhatsApp',
		href: 'https://web.whatsapp.com/send?phone=919868629924&text=Hello,%20I%20need%20help!',
		icon: 'Send',
	},
	{ name: 'Email', href: 'mailto:enquiry@mtalkz.com', icon: 'Mail' },
	{
		name: 'YouTube',
		href: 'https://www.youtube.com/@mtalkzmobility8970',
		icon: 'Youtube',
	},
	{
		name: 'Instagram',
		href: 'https://www.instagram.com/mtalkzmobility/',
		icon: 'Instagram',
	},
];

export const FOOTER_LINKS = {
	products: [
		{ name: 'Bulk SMS', href: '/bulk-sms-services' },
		{ name: 'RCS Messaging', href: '/rcs-messaging' },
		{ name: 'WhatsApp API', href: '/whatsapp-business-api' },
		{ name: 'OTP SMS', href: '/otp-authenticator' },
		{ name: 'IVR Solutions', href: '/ivr-system' },
		{ name: 'WhatsApp Chatbot', href: '/whatsapp-chatbot' },
		{ name: 'E-mail', href: '/bulk-email-marketing-services' },
	],
	businesses: [
		{ name: 'Enterprises', href: '/mtalkz-for-enterprises' },
		{ name: 'Startup', href: '/start-up-program' },
		{ name: 'Developers', href: '/developer-tools' },
	],
	solutions: [
		{ name: 'Healthcare', href: '/healthcare' },
		{ name: 'Travel & Holidays', href: '/industry/travel-holidays' },
		{ name: 'FinTech', href: '/industry/banking' },
		{ name: 'D2C & Retail', href: '/industry/d2c-retail' },
		{ name: 'Insurance', href: '/industry/insurance' },
		{ name: 'Power & Utility', href: '/industry/power-utility' },
		{ name: 'Logistics', href: '/logistics' },
		{ name: 'EdTech', href: '/education' },
		{ name: 'Political', href: '/sms-for-election-campaign' },
	],
	resources: [
		{ name: 'Blog', href: '/blog' },
		{ name: 'Pricing', href: '/pricing' },
		{ name: 'Case Studies', href: '/case-studies' },
		{ name: 'Press Release', href: '/press-release' },
		{ name: 'Client Testimonial', href: '/client-testimonial' },
	],
	company: [
		{ name: 'About Us', href: '/about' },
		{ name: 'Channel Partner', href: '/partnerships' },
		{ name: 'Contact Us', href: '/contact-us' },
		{ name: 'Terms of Service', href: '/terms-of-service' },
		{ name: 'Privacy Policy', href: '/privacy-policy' },
	],
};

export const COMPANY_ASSETS = {
	logo: 'https://mtalkz.com/wp-content/uploads/2022/08/logo-Mtalkz-side-view.webp',
	footerLogo:
		'https://mtalkz.com/wp-content/uploads/2022/08/footer_logo_2.webp',
	trustSeal:
		'https://mtalkz.com/wp-content/uploads/2024/10/Mtalkz-Seal-of-Trust-2-1024x297.webp',
};
