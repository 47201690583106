import {
	Award,
	BookOpen,
	Building,
	Calendar,
	ExternalLink,
	Handshake,
	HelpCircle,
	History,
	MessagesSquare,
	Newspaper,
	Phone,
	UserCircle,
} from 'lucide-react';
import Link from 'next/link';
import type { MenuSectionProps } from '../types';

/**
 * Menu item component for company section
 */
const CompanyMenuItem = ({
	href,
	title,
	description,
	icon,
	isHighlighted = false,
	colorScheme = 'blue',
}: {
	href: string;
	title: string;
	description: string;
	icon: React.ReactNode;
	isHighlighted?: boolean;
	colorScheme?: 'blue' | 'green' | 'purple';
}) => {
	const colors = {
		blue: {
			border: 'border-blue-100',
			hover: 'hover:border-blue-200',
			text: 'group-hover:text-blue-600',
			gradient: 'from-blue-50/50',
		},
		green: {
			border: 'border-green-100',
			hover: 'hover:border-green-200',
			text: 'group-hover:text-green-600',
			gradient: 'from-green-50/50',
		},
		purple: {
			border: 'border-purple-100',
			hover: 'hover:border-purple-200',
			text: 'group-hover:text-purple-600',
			gradient: 'from-purple-50/50',
		},
	};

	const scheme = colors[colorScheme];

	return (
		<Link
			href={href}
			className={`group block rounded-lg border ${
				isHighlighted
					? `${scheme.border} bg-gradient-to-r ${scheme.gradient} to-white`
					: 'border-gray-100 bg-white'
			} p-3.5 transition-all ${scheme.hover} hover:shadow-sm`}>
			<div className="flex items-start gap-2">
				<div className="mt-0.5 flex-shrink-0 text-gray-500">{icon}</div>
				<div>
					<div
						className={`text-sm font-medium text-gray-900 ${scheme.text}`}>
						{title}
					</div>
				</div>
			</div>
		</Link>
	);
};

/**
 * Company specialized menu - optimized for trust and credibility
 */
export function CompanyMenu({ item: _item }: MenuSectionProps) {
	return (
		<div className="menu-content-wrapper">
			<div className="rounded-lg border border-gray-100 bg-white/95 p-4 shadow-lg">
				<div className="mb-4 flex items-center justify-between border-b pb-3">
					<div className="flex items-center gap-2.5">
						<Building className="h-5 w-5 text-blue-500" />
						<h3 className="text-lg text-gray-900">Company</h3>
					</div>
				</div>

				<div className="grid grid-cols-12 gap-5">
					{/* About section */}
					<div className="col-span-4">
						<div className="h-full rounded-lg border border-gray-100 bg-gradient-to-br from-blue-50/50 to-white p-4">
							<h4 className="mb-2.5 text-base text-gray-900">
								About MTalkz
							</h4>
							<p className="mb-4 text-sm leading-relaxed text-gray-600">
								Leading provider of enterprise communication
								solutions. Trusted by businesses worldwide.
							</p>
							<div className="mb-4 grid grid-cols-2 gap-2">
								<div className="rounded-lg border border-blue-100 bg-white p-2.5 text-center">
									<div className="text-sm font-semibold text-blue-600">
										1000+
									</div>
									<div className="text-sm text-gray-600">
										Clients
									</div>
								</div>
								<div className="rounded-lg border border-blue-100 bg-white p-2.5 text-center">
									<div className="text-sm font-semibold text-blue-600">
										99.9%
									</div>
									<div className="text-sm text-gray-600">
										Uptime
									</div>
								</div>
							</div>
							<Link
								href="/about-us"
								className="flex w-full items-center justify-center gap-2 rounded-md bg-gradient-to-r from-blue-600 to-blue-500 px-4 py-2.5 text-base font-medium text-white transition-all hover:from-blue-700 hover:to-blue-600 hover:shadow-md">
								<ExternalLink className="h-4 w-4" />
								<span>Learn More About Us</span>
							</Link>
						</div>
					</div>

					{/* Company links */}
					<div className="col-span-8">
						<div className="grid grid-cols-3 gap-4">
							<div>
								<h4 className="mb-2.5 text-base text-gray-900">
									About Us
								</h4>
								<CompanyMenuItem
									href="/our-story"
									title="Our Story"
									description="Journey of innovation"
									icon={<History className="h-4 w-4" />}
									isHighlighted={true}
									colorScheme="blue"
								/>
								<CompanyMenuItem
									href="/leadership"
									title="Leadership"
									description="Meet our team"
									icon={<Award className="h-4 w-4" />}
									colorScheme="blue"
								/>
							</div>

							<div>
								<h4 className="mb-2.5 text-base text-gray-900">
									Newsroom
								</h4>
								<CompanyMenuItem
									href="/latest-news"
									title="Latest News"
									description="Company updates"
									icon={<Newspaper className="h-4 w-4" />}
									isHighlighted={true}
									colorScheme="green"
								/>
								<CompanyMenuItem
									href="/press-releases"
									title="Press Kit"
									description="Media resources"
									icon={
										<MessagesSquare className="h-4 w-4" />
									}
									colorScheme="green"
								/>
							</div>

							<div>
								<h4 className="mb-2.5 text-base text-gray-900">
									Connect
								</h4>
								<CompanyMenuItem
									href="/careers"
									title="Careers"
									description="Join our team"
									icon={<UserCircle className="h-4 w-4" />}
									isHighlighted={true}
									colorScheme="purple"
								/>
								<CompanyMenuItem
									href="/contact-us"
									title="Contact"
									description="Get in touch"
									icon={<Phone className="h-4 w-4" />}
									colorScheme="purple"
								/>
							</div>
						</div>

						{/* Additional links */}
						<div className="mt-4 border-t border-gray-100 pt-4">
							<div className="mb-2.5">
								<h4 className="text-base text-gray-900">
									Quick Links
								</h4>
							</div>
							<div className="grid grid-cols-4 gap-2">
								<Link
									href="/blog"
									className="group rounded-lg bg-gray-50/80 p-3 text-center transition-colors hover:bg-gray-100">
									<div className="mb-1 flex justify-center">
										<BookOpen className="h-5 w-5 text-blue-500" />
									</div>
									<div className="text-sm text-gray-900 group-hover:text-blue-600">
										Blog
									</div>
									<p className="text-sm text-gray-600 group-hover:text-gray-900">
										Latest posts
									</p>
								</Link>
								<Link
									href="/contact-us"
									className="group rounded-lg bg-gray-50/80 p-3 text-center transition-colors hover:bg-gray-100">
									<div className="mb-1 flex justify-center">
										<Calendar className="h-5 w-5 text-blue-500" />
									</div>
									<div className="text-sm text-gray-900 group-hover:text-blue-600">
										Events
									</div>
									<p className="text-sm text-gray-600 group-hover:text-gray-900">
										Meet us
									</p>
								</Link>
								<Link
									href="/contact-us"
									className="group rounded-lg bg-gray-50/80 p-3 text-center transition-colors hover:bg-gray-100">
									<div className="mb-1 flex justify-center">
										<Handshake className="h-5 w-5 text-blue-500" />
									</div>
									<div className="text-sm text-gray-900 group-hover:text-blue-600">
										Partners
									</div>
									<p className="text-sm text-gray-600 group-hover:text-gray-900">
										Work with us
									</p>
								</Link>
								<Link
									href="/contact-us"
									className="group rounded-lg bg-gray-50/80 p-3 text-center transition-colors hover:bg-gray-100">
									<div className="mb-1 flex justify-center">
										<HelpCircle className="h-5 w-5 text-blue-500" />
									</div>
									<div className="text-sm text-gray-900 group-hover:text-blue-600">
										Support
									</div>
									<p className="text-sm text-gray-600 group-hover:text-gray-900">
										Help center
									</p>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
