import Link from 'next/link';
import { Button } from '@/components/ui/button';

interface GetStartedButtonProps {
	variant?: 'mobile' | 'desktop';
	onClick?: () => void;
}

export function GetStartedButton({
	variant = 'desktop',
	onClick,
}: GetStartedButtonProps) {
	const handleClick = () => {
		const getStartedSection = document.getElementById('get-started');
		if (getStartedSection) {
			getStartedSection.scrollIntoView({ behavior: 'smooth' });
		}
		onClick?.();
	};

	return (
		<Button
			variant="outline"
			className={`${
				variant === 'mobile'
					? 'w-full'
					: 'hidden hover:bg-blue-50 md:inline-flex'
			} transition-colors duration-200`}
			onClick={handleClick}>
			<Link href="/contact-us">Get Started</Link>
		</Button>
	);
}
