import(/* webpackMode: "eager", webpackExports: ["SlugProvider"] */ "/codebuild/output/src2774194422/src/mtalkz.com/app/context/SlugContext.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2774194422/src/mtalkz.com/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2774194422/src/mtalkz.com/components/layout/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2774194422/src/mtalkz.com/components/layout/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2774194422/src/mtalkz.com/components/ui/StickyCTAs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WhatsAppWidget"] */ "/codebuild/output/src2774194422/src/mtalkz.com/components/WhatsAppWidget.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2774194422/src/mtalkz.com/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/codebuild/output/src2774194422/src/mtalkz.com/node_modules/sonner/dist/index.mjs");
