'use client';

import { CalendarClock, Sparkles } from 'lucide-react';
import Link from 'next/link';
import {
	lazy,
	memo,
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { ContactForm } from '@/components/ContactForm';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogTrigger,
} from '@/components/ui/dialog';

interface CTAOption {
	id: number;
	text: string;
	URL?: string;
	target?: '_blank' | '_self' | '_parent' | '_top';
	variant?: 'simple' | 'outline' | 'primary' | 'muted';
	icon?: JSX.Element;
	color?: string;
	hoverColor?: string;
	borderColor?: string;
	dotColor?: string;
	action?: 'link' | 'dialog';
	dialogId?: string;
}

interface StickyCTAsProps {
	CTAs?: CTAOption[];
}

// Button content component
const StickyButton = memo(
	({
		cta,
		position,
		isVisible,
		isMobile,
		animationDelay,
		onClick,
	}: {
		cta: CTAOption;
		position: number;
		isVisible: boolean;
		isMobile: boolean;
		animationDelay: number;
		onClick?: () => void;
	}) => {
		const icon = useMemo(() => {
			return (
				cta.icon ||
				(cta.text?.includes('Demo') ||
				cta.text?.includes('Schedule') ? (
					<CalendarClock
						className="mb-1 h-3.5 w-3.5 opacity-80 transition-all duration-300 group-hover:opacity-100"
						strokeWidth={1.5}
					/>
				) : (
					<Sparkles
						className="mb-1 h-3.5 w-3.5 opacity-80 transition-all duration-300 group-hover:opacity-100"
						strokeWidth={1.5}
					/>
				))
			);
		}, [cta.icon, cta.text]);

		// Get colors based on variant
		const { color, hoverColor, borderColor, dotColor } = useMemo(() => {
			if (
				cta.color &&
				cta.hoverColor &&
				cta.borderColor &&
				cta.dotColor
			) {
				return {
					color: cta.color,
					hoverColor: cta.hoverColor,
					borderColor: cta.borderColor,
					dotColor: cta.dotColor,
				};
			}

			switch (cta.variant) {
				case 'primary':
					return {
						color: '#4262FF',
						hoverColor: '#3451DB',
						borderColor: 'border-blue-400/20',
						dotColor: 'bg-blue-300',
					};
				case 'outline':
					return {
						color: '#ffffff',
						hoverColor: '#f8f8f8',
						borderColor: 'border-gray-400/20',
						dotColor: 'bg-gray-300',
					};
				case 'muted':
					return {
						color: '#6B7280',
						hoverColor: '#4B5563',
						borderColor: 'border-gray-400/20',
						dotColor: 'bg-gray-300',
					};
				case 'simple':
				default:
					return {
						color: '#E0A80C',
						hoverColor: '#c99509',
						borderColor: 'border-amber-400/20',
						dotColor: 'bg-amber-300',
					};
			}
		}, [
			cta.color,
			cta.hoverColor,
			cta.borderColor,
			cta.dotColor,
			cta.variant,
		]);

		// Simplified style calculation
		const buttonStyle = useMemo(
			() => ({
				top: `${position}vh`,
				'--bg-color': color,
				'--hover-bg-color': hoverColor,
				padding: isMobile
					? '0.25rem 0.25rem 0.75rem 0.25rem'
					: '0.375rem 0.375rem 1rem 0.375rem',
				transitionDelay: `${animationDelay}ms`,
			}),
			[position, color, hoverColor, isMobile, animationDelay],
		) as React.CSSProperties;

		return (
			<div
				className={`group fixed right-0 z-50 flex cursor-pointer flex-col items-center justify-center rounded-l-md border-b border-l border-t ${borderColor} bg-[var(--bg-color)] text-white shadow-md transition-all duration-300 ease-in-out hover:-translate-x-0.5 hover:bg-[var(--hover-bg-color)] hover:shadow-lg ${isVisible ? 'translate-x-0' : 'translate-x-full'}`}
				style={buttonStyle}
				onClick={onClick}
				role="button"
				tabIndex={0}
				aria-label={cta.text}>
				<div className="flex rotate-180 items-center gap-1 p-0 [writing-mode:vertical-lr]">
					<div className="relative transition-transform duration-300 group-hover:scale-110">
						{icon}
						<span
							className={`absolute right-0 top-0 h-1.5 w-1.5 rounded-full ${dotColor} opacity-0 transition-opacity duration-500 group-hover:animate-ping group-hover:opacity-100`}></span>
					</div>
					<span className="text-xs font-medium tracking-wide transition-all duration-300 group-hover:font-semibold">
						{cta.text}
					</span>
				</div>

				{/* Tooltip - now using pure CSS hover */}
				<div className="pointer-events-none absolute right-full top-1/2 mr-2 -translate-y-1/2 whitespace-nowrap rounded bg-gray-800 px-2 py-1 text-xs text-white opacity-0 transition-opacity duration-200 group-hover:opacity-90">
					{cta.text}
				</div>
			</div>
		);
	},
);

StickyButton.displayName = 'StickyButton';

// Link CTA component
const LinkCTA = memo(
	({
		cta,
		position,
		isVisible,
		isMobile,
		animationDelay,
	}: {
		cta: CTAOption;
		position: number;
		isVisible: boolean;
		isMobile: boolean;
		animationDelay: number;
	}) => {
		return (
			<Link href={cta.URL || '#'} target={cta.target || '_self'} passHref>
				<StickyButton
					cta={cta}
					position={position}
					isVisible={isVisible}
					isMobile={isMobile}
					animationDelay={animationDelay}
				/>
			</Link>
		);
	},
);

LinkCTA.displayName = 'LinkCTA';

// Dialog CTA component
const DialogCTA = memo(
	({
		cta,
		position,
		isVisible,
		isMobile,
		animationDelay,
		isOpen,
		onOpenChange,
	}: {
		cta: CTAOption;
		position: number;
		isVisible: boolean;
		isMobile: boolean;
		animationDelay: number;
		isOpen: boolean;
		onOpenChange: (open: boolean) => void;
	}) => {
		return (
			<Dialog
				open={isOpen}
				onOpenChange={onOpenChange}
				data-dialog-id={cta.dialogId || `sticky-cta-dialog-${cta.id}`}>
				<DialogTrigger asChild>
					<div>
						<StickyButton
							cta={cta}
							position={position}
							isVisible={isVisible}
							isMobile={isMobile}
							animationDelay={animationDelay}
						/>
					</div>
				</DialogTrigger>
				{isOpen && (
					<DialogContent className="m-0 p-0">
						<DialogTitle className="sr-only">
							{cta.text}
						</DialogTitle>
						<ContactForm />
					</DialogContent>
				)}
			</Dialog>
		);
	},
);

DialogCTA.displayName = 'DialogCTA';

const StickyCTAs = memo(({ CTAs }: StickyCTAsProps) => {
	const [openDialog, setOpenDialog] = useState<number | null>(null);
	const [isMobile, setIsMobile] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [windowHeight, setWindowHeight] = useState(0);

	// Detect viewport size and set window height
	useEffect(() => {
		const checkIfMobile = () => {
			setIsMobile(window.innerWidth < 768);
			setWindowHeight(window.innerHeight);
		};

		// Initial check
		checkIfMobile();

		// Add event listener
		window.addEventListener('resize', checkIfMobile);

		// Cleanup
		return () => window.removeEventListener('resize', checkIfMobile);
	}, []);

	// Animation on mount
	useEffect(() => {
		// Delay the appearance for a smoother page load experience
		const timer = setTimeout(() => {
			setIsVisible(true);
		}, 800);

		return () => clearTimeout(timer);
	}, []);

	const defaultCTAs: CTAOption[] = useMemo(
		() => [
			{
				id: 1,
				text: 'Schedule Demo',
				variant: 'primary',
				icon: (
					<CalendarClock
						className="mb-1 h-3.5 w-3.5 opacity-80 transition-all duration-300 group-hover:opacity-100"
						strokeWidth={1.5}
					/>
				),
				color: '#E0A80C',
				hoverColor: '#c99509',
				borderColor: 'border-amber-400/20',
				dotColor: 'bg-amber-300',
				action: 'dialog',
				dialogId: 'sticky-demo-dialog',
			},
			{
				id: 2,
				text: 'View Pricing',
				URL: '/#pricing',
				variant: 'primary',
				target: '_self',
				icon: (
					<Sparkles
						className="mb-1 h-3.5 w-3.5 opacity-80 transition-all duration-300 group-hover:opacity-100"
						strokeWidth={1.5}
					/>
				),
				color: '#4262FF',
				hoverColor: '#3451DB',
				borderColor: 'border-blue-400/20',
				dotColor: 'bg-blue-300',
				action: 'link',
			},
		],
		[],
	);

	// Memoize the processed CTAs to prevent recalculation on every render
	const processedCTAs = useMemo(() => {
		const ctaOptions = CTAs || defaultCTAs;
		return ctaOptions.map(cta => {
			if (cta.action === 'dialog' && !cta.dialogId) {
				return { ...cta, dialogId: `sticky-cta-dialog-${cta.id}` };
			}
			return cta;
		});
	}, [CTAs, defaultCTAs]);

	const calculatePosition = useCallback(
		(index: number) => {
			// Calculate position based on viewport size and number of buttons
			const totalButtons = processedCTAs.length;
			const buttonHeight = isMobile ? 80 : 100; // Estimated button height in pixels
			const spacing = isMobile ? 60 : 50; // Spacing between buttons in pixels

			// Calculate vertical position - safely handle window access
			let position = 40 + index * 15; // Default fallback position

			if (typeof window !== 'undefined' && windowHeight > 0) {
				// For desktop: center the group of buttons and space them evenly
				// For mobile: position them closer together
				const basePosition =
					50 -
					((totalButtons * buttonHeight +
						(totalButtons - 1) * spacing) /
						2 /
						windowHeight) *
						100;
				position =
					basePosition +
					((index * (buttonHeight + spacing)) / windowHeight) * 100;
			}

			return position;
		},
		[isMobile, processedCTAs.length, windowHeight],
	);

	// Memoize the rendered CTAs to prevent unnecessary re-renders
	const renderedCTAs = useMemo(() => {
		return processedCTAs.map((cta, index) => {
			const position = calculatePosition(index);
			const animationDelay = 100 + index * 150;

			const action = cta.action || (cta.URL ? 'link' : 'dialog');

			if (action === 'link' && cta.URL) {
				return (
					<LinkCTA
						key={`link-${cta.id}`}
						cta={cta}
						position={position}
						isVisible={isVisible}
						isMobile={isMobile}
						animationDelay={animationDelay}
					/>
				);
			} else if (action === 'dialog') {
				return (
					<DialogCTA
						key={`dialog-${cta.id}`}
						cta={cta}
						position={position}
						isVisible={isVisible}
						isMobile={isMobile}
						animationDelay={animationDelay}
						isOpen={openDialog === cta.id}
						onOpenChange={open =>
							setOpenDialog(open ? cta.id : null)
						}
					/>
				);
			}

			return null;
		});
	}, [
		processedCTAs,
		calculatePosition,
		isVisible,
		isMobile,
		openDialog,
		setOpenDialog,
	]);

	return (
		<>
			<style jsx global>{`
				@keyframes pulse {
					0% {
						box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
					}
					70% {
						box-shadow: 0 0 0 6px rgba(255, 255, 255, 0);
					}
					100% {
						box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
					}
				}

				@keyframes bounce {
					0%,
					100% {
						transform: translateY(0);
					}
					50% {
						transform: translateY(-5px);
					}
				}

				@keyframes ping {
					0% {
						transform: scale(1);
						opacity: 1;
					}
					75%,
					100% {
						transform: scale(2);
						opacity: 0;
					}
				}
			`}</style>

			{renderedCTAs}
		</>
	);
});

StickyCTAs.displayName = 'StickyCTAs';

export default StickyCTAs;
