import {
	Bot as BotIcon,
	Box,
	ChevronRight,
	Database,
	Globe,
	Headphones,
	Keyboard,
	Mail,
	Mail as MailIcon,
	MessageCircle,
	MessageSquare,
	MessagesSquare,
	Phone,
	PhoneIncoming,
	PhoneMissed,
	PhoneOutgoing,
	Shield,
	ShieldCheck,
	Smartphone,
} from 'lucide-react';
import Link from 'next/link';
import { memo } from 'react';
import { MenuItem } from '@/types/menu';
import type { MenuSectionProps } from '../types';

// Menu Item Component with Icons
const MenuItemComponent = ({
	item,
	isHighlighted = false,
	colorScheme = 'blue',
	icon,
}: {
	item: MenuItem;
	isHighlighted?: boolean;
	colorScheme?: 'blue' | 'green' | 'purple' | 'orange' | 'indigo';
	icon?: React.ReactNode;
}) => {
	const colors = {
		blue: {
			border: 'border-blue-100',
			hover: 'hover:border-blue-200 hover:bg-blue-50',
			text: 'group-hover:text-blue-600',
			gradient: 'from-blue-50/50',
		},
		green: {
			border: 'border-green-100',
			hover: 'hover:border-green-200 hover:bg-green-50',
			text: 'group-hover:text-green-600',
			gradient: 'from-green-50/50',
		},
		purple: {
			border: 'border-purple-100',
			hover: 'hover:border-purple-200 hover:bg-purple-50',
			text: 'group-hover:text-purple-600',
			gradient: 'from-purple-50/50',
		},
		orange: {
			border: 'border-orange-100',
			hover: 'hover:border-orange-200 hover:bg-orange-50',
			text: 'group-hover:text-orange-600',
			gradient: 'from-orange-50/50',
		},
		indigo: {
			border: 'border-indigo-100',
			hover: 'hover:border-indigo-200 hover:bg-indigo-50',
			text: 'group-hover:text-indigo-600',
			gradient: 'from-indigo-50/50',
		},
	};

	const scheme = colors[colorScheme];

	return (
		<Link
			href={item.href || '#'}
			className={`group mb-1 block rounded-lg border ${
				isHighlighted
					? `${scheme.border} bg-gradient-to-r ${scheme.gradient} to-white`
					: 'border-gray-100 bg-white'
			} p-2.5 transition-all ${scheme.hover} hover:shadow-sm`}>
			<div className="flex items-start justify-between gap-2">
				<div className="min-w-0 flex-1">
					<div className="flex items-start gap-2">
						{icon && (
							<div className="mt-0.5 flex-shrink-0 text-blue-500">
								{icon}
							</div>
						)}
						<div>
							<span
								className={`text-sm text-gray-900 ${scheme.text}`}>
								{item.name}
							</span>
						</div>
					</div>
				</div>
				<div className="ml-2 shrink-0 text-gray-400 group-hover:text-gray-600">
					<ChevronRight className="h-4 w-4" />
				</div>
			</div>
		</Link>
	);
};

// Category Component that handles a group of items
const CategoryComponent = memo(function CategoryComponent({
	category,
}: {
	category: MenuItem;
}) {
	// Get icon for category item
	const getIconForItem = (name: string): React.ReactNode => {
		switch (name) {
			// SMS Messaging icons
			case 'Bulk SMS':
			case 'Bulk SMS Service':
				return <MessageSquare className="h-4 w-4" />;
			case 'OTP Authenticator':
				return <ShieldCheck className="h-4 w-4" />;
			case 'Long Code Service':
				return <Smartphone className="h-4 w-4" />;
			case 'Shortcode':
				return <MessageSquare className="h-4 w-4" />;
			case 'Email To SMS':
				return <Mail className="h-4 w-4" />;
			case 'International SMS':
				return <Globe className="h-4 w-4" />;

			// Voice Solutions icons
			case 'Voice Services':
				return <Phone className="h-4 w-4" />;
			case 'IVR System':
				return <Keyboard className="h-4 w-4" />;
			case 'Voice OTP':
				return <Headphones className="h-4 w-4" />;
			case 'Outbound Dialing Service':
				return <PhoneOutgoing className="h-4 w-4" />;
			case 'Inbound Dialing Service':
				return <PhoneIncoming className="h-4 w-4" />;
			case 'Missed call service':
				return <PhoneMissed className="h-4 w-4" />;
			case 'Number masking':
				return <Shield className="h-4 w-4" />;

			// WhatsApp Solutions icons
			case 'WhatsApp API':
			case 'WhatsApp Business API':
				return <MessageCircle className="h-4 w-4" />;
			case 'Conversational AI Chat':
			case 'Conversational AI Chatbot':
				return <BotIcon className="h-4 w-4" />;
			case 'WhatsApp CRM':
				return <Database className="h-4 w-4" />;
			case 'WhatsApp Chatbot':
				return <MessageSquare className="h-4 w-4" />;

			// Other icons
			case 'RCS Messaging':
				return <MessagesSquare className="h-4 w-4" />;
			case 'Email Solutions':
			case 'Email Solution':
			case 'Bulk Email Marketing Services':
				return <MailIcon className="h-4 w-4" />;
			default:
				// Fallback icons based on category
				if (category.name === 'SMS Messaging') {
					return <MessageSquare className="h-4 w-4" />;
				} else if (category.name === 'Voice Solutions') {
					return <Phone className="h-4 w-4" />;
				} else if (category.name === 'WhatsApp Solutions') {
					return <MessageCircle className="h-4 w-4" />;
				} else if (category.name === 'RCS Messaging') {
					return <MessagesSquare className="h-4 w-4" />;
				} else if (category.name.includes('Email')) {
					return <MailIcon className="h-4 w-4" />;
				}
				return null;
		}
	};

	// Determine color scheme based on category name
	const getColorScheme = (
		name: string,
	): 'blue' | 'green' | 'purple' | 'orange' | 'indigo' => {
		switch (name) {
			case 'SMS Messaging':
				return 'blue';
			case 'Voice Solutions':
				return 'green';
			case 'WhatsApp Solutions':
				return 'purple';
			case 'RCS Messaging':
				return 'orange';
			case 'Bulk Email Marketing Services':
				return 'indigo';
			default:
				return 'blue';
		}
	};

	const colorScheme = getColorScheme(category.name);

	return (
		<div>
			<h4 className="mb-1 text-sm font-medium text-gray-900">
				{category.name}
			</h4>
			{category.submenu?.map((subItem, index) => (
				<MenuItemComponent
					key={subItem.name}
					item={subItem}
					isHighlighted={index === 0}
					colorScheme={colorScheme}
					icon={getIconForItem(subItem.name)}
				/>
			))}
			{!category.submenu?.length && category.href && (
				<MenuItemComponent
					item={category}
					isHighlighted={true}
					colorScheme={colorScheme}
					icon={getIconForItem(category.name)}
				/>
			)}
		</div>
	);
});

/**
 * Product specialized menu - optimized for product showcase
 */
export function ProductMenu({ item: _item }: MenuSectionProps) {
	if (!_item?.submenu) {
		console.log('No submenu found in product menu');
		return null;
	}

	// Find each category
	const smsCategory = _item.submenu.find(cat => cat.name === 'SMS Messaging');
	const voiceCategory = _item.submenu.find(
		cat => cat.name === 'Voice Solutions',
	);
	const whatsappCategory = _item.submenu.find(
		cat => cat.name === 'WhatsApp Solutions',
	);
	const rcsCategory = _item.submenu.find(cat => cat.name === 'RCS Messaging');
	const emailCategory = _item.submenu.find(
		cat => cat.name === 'Bulk Email Marketing Services',
	);

	return (
		<div className="menu-content-wrapper w-full">
			<div className="w-full rounded-lg border border-gray-100 bg-white/95 p-2.5 shadow-lg">
				<div className="mb-2 flex items-center justify-between border-b pb-1.5">
					<div className="flex items-center gap-2">
						<Box className="h-5 w-5 text-blue-500" />
						<h3 className="text-base font-semibold text-gray-900">
							Products
						</h3>
					</div>
				</div>

				<div className="grid grid-cols-4 gap-2">
					{/* SMS Messaging */}
					<div>
						{smsCategory && (
							<CategoryComponent category={smsCategory} />
						)}
					</div>

					{/* Voice Solutions */}
					<div>
						{voiceCategory && (
							<CategoryComponent category={voiceCategory} />
						)}
					</div>

					{/* WhatsApp Solutions */}
					<div>
						{whatsappCategory && (
							<CategoryComponent category={whatsappCategory} />
						)}
					</div>

					{/* RCS & Email */}
					<div>
						{rcsCategory && (
							<CategoryComponent category={rcsCategory} />
						)}
						{emailCategory && (
							<CategoryComponent category={emailCategory} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
