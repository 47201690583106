'use client';

import React, { memo, useRef } from 'react';
import {
	NavigationMenu,
	NavigationMenuContent,
	NavigationMenuItem,
	NavigationMenuList,
	NavigationMenuTrigger,
	NavigationMenuViewport,
} from '@/components/ui/navigation-menu';
import type { MenuItem } from '@/types/menu';
import { CompanyMenu } from './menu-sections/company-menu';
import { DeveloperToolsMenu } from './menu-sections/developer-tools-menu';
import { PricingMenu } from './menu-sections/pricing-menu';
import { ProductMenu } from './menu-sections/product-menu';
import { ResourcesMenu } from './menu-sections/resources-menu';
import { SolutionsMenu } from './menu-sections/solutions-menu';
import { StandardMenuContent } from './menu-sections/standard-menu-content';
import type { DesktopNavigationProps } from './types';
import { renderIcon } from './utils';
/**
 * Desktop navigation component
 */
export const DesktopNavigation = memo(function DesktopNavigation({
	menuItems,
}: DesktopNavigationProps) {
	const navigationMenuRef = useRef<HTMLDivElement>(null);

	const handleLinkClick = () => {
		// Find and click the trigger to close the menu
		const openTrigger = navigationMenuRef.current?.querySelector(
			'[data-state="open"]',
		) as HTMLButtonElement;
		if (openTrigger) {
			openTrigger.click();
		}
	};

	return (
		<NavigationMenu
			ref={navigationMenuRef}
			className="mx-auto hidden lg:flex">
			<NavigationMenuList className="gap-1">
				{menuItems.map((item, index) => (
					<NavigationMenuItem key={index}>
						<NavigationMenuTrigger className="menu-trigger group h-10 bg-white transition-colors focus:bg-gray-50 data-[state=open]:bg-gray-50 data-[state=open]:text-primary">
							<span className="flex items-center gap-1.5 group-data-[state=open]:text-primary">
								{item.icon &&
									renderIcon(
										item.icon,
										'h-4 w-4 text-primary group-data-[state=open]:text-primary',
									)}
								{item.name}
							</span>
						</NavigationMenuTrigger>
						<NavigationMenuContent
							className="z-50"
							onClick={handleLinkClick}>
							{item.submenu && (
								<div className="w-[min(900px,90vw)] p-1 backdrop-blur-sm">
									{renderMenuContent(item)}
								</div>
							)}
						</NavigationMenuContent>
					</NavigationMenuItem>
				))}
			</NavigationMenuList>
			<NavigationMenuViewport />
		</NavigationMenu>
	);
});

/**
 * Renders the appropriate menu content based on the menu item name
 */
function renderMenuContent(item: MenuItem) {
	switch (item.name) {
		case 'Solution':
			return <SolutionsMenu item={item} />;
		case 'Products':
			return <ProductMenu item={item} />;
		case 'Developer Tools':
			return <DeveloperToolsMenu item={item} />;
		case 'Pricing':
			return <PricingMenu item={item} />;
		case 'Resources':
			return <ResourcesMenu item={item} />;
		case 'Company':
			return <CompanyMenu item={item} />;
		default:
			return <StandardMenuContent item={item} />;
	}
}
