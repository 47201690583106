import {
	Bell,
	Code,
	FileCode,
	HelpCircle,
	Key,
	Mail,
	MessageSquare,
	Phone,
	Terminal,
} from 'lucide-react';
import Link from 'next/link';
import type { MenuSectionProps } from '../types';

/**
 * Menu item component for developer tools
 */
const DevMenuItem = ({
	href,
	title,
	description,
	icon,
	isHighlighted = false,
}: {
	href: string;
	title: string;
	description: string;
	icon: React.ReactNode;
	isHighlighted?: boolean;
}) => {
	return (
		<Link
			href={href}
			className={`block rounded-md border ${
				isHighlighted
					? 'border-blue-100 bg-gradient-to-r from-blue-50/50 to-white'
					: 'border-gray-100 bg-white'
			} p-3 transition-all hover:border-blue-100 hover:bg-gradient-to-br hover:from-blue-50/50 hover:to-white hover:shadow-sm`}>
			<div className="flex items-start gap-2">
				<div className="mt-0.5 flex-shrink-0 text-blue-500">{icon}</div>
				<div>
					<div className="text-sm font-medium text-gray-900">
						{title}
					</div>
				</div>
			</div>
		</Link>
	);
};

/**
 * Developer Tools specialized menu
 */
export function DeveloperToolsMenu({ item: _item }: MenuSectionProps) {
	return (
		<div className="menu-content-wrapper">
			<div className="rounded-lg border border-gray-100 bg-white/95 p-4 shadow-lg">
				<div className="mb-3 flex items-center justify-between border-b pb-2">
					<div className="flex items-center gap-2">
						<Code className="h-6 w-6 text-blue-500" />
						<h3 className="text-lg text-gray-900">
							Developer Tools
						</h3>
					</div>
					<Link
						href="/docs"
						className="rounded bg-blue-50 px-3 py-1.5 text-sm text-blue-600 transition-colors hover:bg-blue-100">
						API Documentation
					</Link>
				</div>

				<div className="grid grid-cols-12 gap-4">
					{/* Main API Links */}
					<div className="col-span-7 space-y-2">
						<div className="mb-2 flex items-center gap-2">
							<Terminal className="h-5 w-5 text-gray-500" />
							<h4 className="text-base text-gray-900">
								API Integration
							</h4>
						</div>
						<DevMenuItem
							href="/voice-api-for-developer"
							title="Voice API For Developer"
							description="Programmable voice calls, IVR & call tracking"
							icon={<Phone className="h-4 w-4" />}
							isHighlighted={true}
						/>
						<DevMenuItem
							href="/otp-api-for-developer"
							title="OTP API For Developer"
							description="Phone verification & two-factor authentication"
							icon={<Key className="h-4 w-4" />}
						/>
						<DevMenuItem
							href="/sms-api-for-developer"
							title="SMS API For Developer"
							description="Global SMS coverage with high deliverability"
							icon={<MessageSquare className="h-4 w-4" />}
						/>
						<DevMenuItem
							href="/email-api-for-developer"
							title="Email API For Developer"
							description="Transactional & marketing email delivery"
							icon={<Mail className="h-4 w-4" />}
						/>
					</div>

					{/* Quick Access */}
					<div className="col-span-5">
						<div className="mb-2 flex items-center gap-2">
							<Key className="h-5 w-5 text-gray-500" />
							<h4 className="text-base text-gray-900">
								Quick Access
							</h4>
						</div>
						<div className="space-y-2">
							<Link
								href="/docs/api-key"
								className="flex items-center gap-3 rounded-md border border-gray-100 bg-gradient-to-r from-gray-50 to-white p-3 transition-all hover:border-blue-100 hover:bg-gradient-to-br hover:from-blue-50/50 hover:to-white hover:shadow-sm">
								<div className="flex-shrink-0 text-blue-500">
									<FileCode className="h-4 w-4" />
								</div>
								<div className="flex-1">
									<div className="text-sm text-gray-900">
										API Keys
									</div>
									<p className="text-xs text-gray-500">
										Get your API credentials
									</p>
								</div>
							</Link>
							<Link
								href="/docs/webhooks"
								className="flex items-center gap-3 rounded-md border border-gray-100 bg-gradient-to-r from-gray-50 to-white p-3 transition-all hover:border-blue-100 hover:bg-gradient-to-br hover:from-blue-50/50 hover:to-white hover:shadow-sm">
								<div className="flex-shrink-0 text-blue-500">
									<Bell className="h-4 w-4" />
								</div>
								<div className="flex-1">
									<div className="text-sm text-gray-900">
										Webhooks
									</div>
									<p className="text-xs text-gray-500">
										Setup delivery reports
									</p>
								</div>
							</Link>
							<div className="rounded-md border border-blue-100 bg-blue-50/50 p-3">
								<div className="flex items-start gap-2">
									<div className="mt-0.5 flex-shrink-0 text-blue-500">
										<HelpCircle className="h-4 w-4" />
									</div>
									<div>
										<div className="text-sm text-gray-900">
											Need Help?
										</div>
										<p className="mb-2 text-xs text-gray-600">
											Get support from our developer team
										</p>
										<Link
											href="/contact-us"
											className="text-xs text-blue-600 hover:text-blue-700">
											Contact Support →
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
