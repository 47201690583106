'use client';

import { useEffect, useRef } from 'react';
import { components } from '@/types/strapi';

interface ContactFormProps {
	script?: components['schemas']['SharedScriptComponent'];
}

export function ContactForm({
	script = {
		src: 'https://mtalkz1.myfreshworks.com/crm/sales/web_forms/fc49e5fa6c2de028b64c55b7d04c8d27a8661940215d3b3da1de96fe2ac2edd1/form.js',
		script_id:
			'fs_fc49e5fa6c2de028b64c55b7d04c8d27a8661940215d3b3da1de96fe2ac2edd1',
		defer: true,
		async: false,
	},
}: ContactFormProps) {
	const containerRef = useRef<HTMLDivElement>(null);
	const hasLoaded = useRef(false);

	useEffect(() => {
		if (
			!script?.src ||
			!script.script_id ||
			!containerRef.current ||
			hasLoaded.current
		)
			return;

		const observer = new IntersectionObserver(
			entries => {
				if (entries[0].isIntersecting) {
					// Preconnect
					const preconnect = document.createElement('link');
					preconnect.rel = 'preconnect';
					preconnect.href = 'https://mtalkz1.myfreshworks.com';
					preconnect.crossOrigin = 'anonymous';
					document.head.appendChild(preconnect);

					// Create script
					const scriptElement = document.createElement('script');
					scriptElement.src = script.src!;
					scriptElement.id = script.script_id!;
					scriptElement.crossOrigin = 'anonymous';
					scriptElement.defer = true;

					scriptElement.onerror = () =>
						console.error('Failed to load form script');
					scriptElement.onload = () =>
						console.log('Form script loaded successfully');

					containerRef.current!.appendChild(scriptElement);
					hasLoaded.current = true; // Prevent re-loading
					observer.disconnect();
				}
			},
			{ rootMargin: '100px' }, // Load 100px before visible
		);

		observer.observe(containerRef.current);

		return () => observer.disconnect();
	}, [script]);

	return (
		<div
			ref={containerRef}
			className="m-0 border-transparent bg-transparent p-0"
		/>
	);
}
