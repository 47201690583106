import Image from 'next/image';
import Link from 'next/link';
import { COMPANY_INFO } from '@/lib/constants';

interface LogoProps {
	className?: string;
}

export function Logo({ className = 'h-8 w-auto' }: LogoProps) {
	return (
		<Link
			href="/"
			className="flex-shrink-0"
			aria-label={`${COMPANY_INFO.name} home`}>
			<Image
				src={COMPANY_INFO.logo}
				alt={COMPANY_INFO.logoAlt}
				width={120}
				height={30}
				className={className}
				priority
			/>
		</Link>
	);
}
