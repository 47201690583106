import {
	Calculator,
	HandCoins,
	IndianRupee,
	Mail,
	MessageSquare,
	Phone,
	Sparkles,
} from 'lucide-react';
import Link from 'next/link';
import { Button } from '@/components/ui/button';
import type { MenuSectionProps } from '../types';

/**
 * Menu item component for pricing
 */
const PriceMenuItem = ({
	href,
	title,
	price,
	icon,
	isHighlighted = false,
	colorScheme = 'blue',
}: {
	href: string;
	title: string;
	price: string;
	icon: React.ReactNode;
	isHighlighted?: boolean;
	colorScheme?: 'blue' | 'green' | 'purple';
}) => {
	const colors = {
		blue: {
			border: 'border-blue-100',
			hover: 'hover:border-blue-200 hover:bg-blue-50',
			text: 'group-hover:text-blue-600',
			gradient: 'from-blue-50/50',
		},
		green: {
			border: 'border-green-100',
			hover: 'hover:border-green-200 hover:bg-green-50',
			text: 'group-hover:text-green-600',
			gradient: 'from-green-50/50',
		},
		purple: {
			border: 'border-purple-100',
			hover: 'hover:border-purple-200 hover:bg-purple-50',
			text: 'group-hover:text-purple-600',
			gradient: 'from-purple-50/50',
		},
	};

	const scheme = colors[colorScheme];

	return (
		<Link
			href={href}
			className={`group block rounded-lg border ${
				isHighlighted
					? `${scheme.border} bg-gradient-to-r ${scheme.gradient} to-white`
					: 'border-gray-100 bg-white'
			} p-3.5 transition-all ${scheme.hover} hover:shadow-sm`}>
			<div className="flex items-start gap-2">
				<div className="mt-0.5 flex-shrink-0 text-gray-500">{icon}</div>
				<div>
					<div
						className={`text-sm font-medium text-gray-900 ${scheme.text}`}>
						{title}
					</div>
				</div>
			</div>
		</Link>
	);
};

/**
 * Pricing specialized menu - shows product prices on hover
 */
export function PricingMenu({ item: _item }: MenuSectionProps) {
	return (
		<div className="menu-content-wrapper">
			<div className="rounded-lg border border-gray-100 bg-white/95 p-4 shadow-lg">
				<div className="mb-4 flex items-center justify-between border-b pb-3">
					<div className="flex items-center gap-2.5">
						<IndianRupee className="h-5 w-5 text-blue-500" />
						<h3 className="text-lg text-gray-900">Pricing</h3>
					</div>
					{/* <Link
						href="/#pricing"
						className="rounded-full bg-blue-50 px-4 py-1.5 text-sm text-blue-600 transition-colors hover:bg-blue-100">
						<div className="flex items-center gap-1">
							<Calculator className="h-4 w-4" />
							<span>Price Calculator</span>
						</div>
					</Link> */}
				</div>

				<div className="grid grid-cols-12 gap-5">
					{/* Overview section */}
					<div className="col-span-4">
						<div className="h-full rounded-lg border border-gray-100 bg-gradient-to-br from-blue-50/50 to-white p-4">
							<h4 className="mb-2.5 text-base text-gray-900">
								Custom Pricing
							</h4>
							<p className="mb-4 text-sm leading-relaxed text-gray-600">
								Get personalized rates based on your volume. Our
								enterprise customers save up to 40% on bulk
								messaging.
							</p>
							<div className="mb-4 space-y-2">
								<div className="flex items-center gap-2 rounded-lg border border-blue-100 bg-white p-2.5 text-sm text-gray-600">
									<Sparkles className="h-4 w-4 text-blue-500" />
									<span>
										Volume-based discounts up to 40% off
									</span>
								</div>
								<div className="flex items-center gap-2 rounded-lg border border-blue-100 bg-white p-2.5 text-sm text-gray-600">
									<Sparkles className="h-4 w-4 text-blue-500" />
									<span>Dedicated account manager</span>
								</div>
								<div className="flex items-center gap-2 rounded-lg border border-blue-100 bg-white p-2.5 text-sm text-gray-600">
									<Sparkles className="h-4 w-4 text-blue-500" />
									<span>
										Priority support & SLA guarantees
									</span>
								</div>
							</div>

							<Button>
								<Link href="/contact-us">Get Custom Quote</Link>
							</Button>
							{/* <PriceMenuItem
									href={'/contact-us'}
									title="Get Custom Quote"
									price="Starting from ₹0.15/email"
									icon={
										<HandCoins className="h-4 w-4 text-blue-500" />
									}
									colorScheme="blue"
								/> */}
						</div>
					</div>

					{/* Pricing categories */}
					<div className="col-span-8">
						<div className="grid grid-cols-3 gap-4">
							<div>
								<h4 className="mb-2.5 text-base text-gray-900">
									Messaging Services
								</h4>
								<PriceMenuItem
									href={
										_item.submenu?.[0]?.href ??
										'/contact-us'
									}
									title="SMS Messaging"
									price="Starting from ₹0.12/SMS"
									icon={<MessageSquare className="h-4 w-4" />}
									isHighlighted={true}
									colorScheme="blue"
								/>
								<PriceMenuItem
									href={
										_item.submenu?.[1]?.href ??
										'/contact-us'
									}
									title="WhatsApp Business"
									price="Starting from ₹0.30/message"
									icon={<MessageSquare className="h-4 w-4" />}
									colorScheme="blue"
								/>
							</div>

							<div>
								<h4 className="mb-2.5 text-base text-gray-900">
									Voice & RCS
								</h4>
								<PriceMenuItem
									href={
										_item.submenu?.[2]?.href ??
										'/contact-us'
									}
									title="Voice Solutions"
									price="Starting from ₹0.50/minute"
									icon={<Phone className="h-4 w-4" />}
									isHighlighted={true}
									colorScheme="green"
								/>
								<PriceMenuItem
									href={
										_item.submenu?.[3]?.href ??
										'/contact-us'
									}
									title="RCS Messaging"
									price="Starting from ₹0.45/message"
									icon={<MessageSquare className="h-4 w-4" />}
									colorScheme="green"
								/>
							</div>

							<div>
								<h4 className="mb-2.5 text-base text-gray-900">
									Email Services
								</h4>
								<PriceMenuItem
									href={
										_item.submenu?.[4]?.href ??
										'/contact-us'
									}
									title="Bulk Email"
									price="Starting from ₹0.10/email"
									icon={<Mail className="h-4 w-4" />}
									isHighlighted={true}
									colorScheme="purple"
								/>
								<PriceMenuItem
									href={
										_item.submenu?.[4]?.href ??
										'/contact-us'
									}
									title="Transactional Email"
									price="Starting from ₹0.15/email"
									icon={<Mail className="h-4 w-4" />}
									colorScheme="purple"
								/>
							</div>
						</div>

						{/* Additional pricing information */}
						<div className="mt-4 border-t border-gray-100 pt-4">
							<div className="mb-2.5">
								<h4 className="text-base text-gray-900">
									Volume Discounts
								</h4>
							</div>
							<div className="grid grid-cols-4 gap-2">
								<div className="group rounded-lg border border-gray-100 bg-white p-3 text-center transition-all hover:border-blue-100 hover:bg-blue-50 hover:shadow-sm">
									<div className="text-sm text-gray-900 group-hover:text-blue-600">
										100K+ Credits
									</div>
									<p className="text-sm text-gray-600 group-hover:text-gray-900">
										5% off
									</p>
								</div>
								<div className="group rounded-lg border border-gray-100 bg-white p-3 text-center transition-all hover:border-blue-100 hover:bg-blue-50 hover:shadow-sm">
									<div className="text-sm text-gray-900 group-hover:text-blue-600">
										500K+ Credits
									</div>
									<p className="text-sm text-gray-600 group-hover:text-gray-900">
										10% off
									</p>
								</div>
								<div className="group rounded-lg border border-gray-100 bg-white p-3 text-center transition-all hover:border-blue-100 hover:bg-blue-50 hover:shadow-sm">
									<div className="text-sm text-gray-900 group-hover:text-blue-600">
										1M+ Credits
									</div>
									<p className="text-sm text-gray-600 group-hover:text-gray-900">
										15% off
									</p>
								</div>
								<div className="group rounded-lg border border-gray-100 bg-white p-3 text-center transition-all hover:border-blue-100 hover:bg-blue-50 hover:shadow-sm">
									<div className="text-sm text-gray-900 group-hover:text-blue-600">
										5M+ Credits
									</div>
									<p className="text-sm text-gray-600 group-hover:text-gray-900">
										Custom pricing
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
