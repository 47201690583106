import { Globe } from 'lucide-react';
import Link from 'next/link';
import { memo } from 'react';
import { MenuItem } from '@/types/menu';
import type { MenuSectionProps } from '../types';
import { renderIcon } from '../utils';

// Memoized Menu Header Component
const MenuHeader = memo(function MenuHeader({ item }: { item: MenuItem }) {
	return (
		<div className="mb-2 flex items-center border-b pb-2">
			<div className="flex items-center gap-2.5">
				{item.icon ? (
					renderIcon(item.icon, 'h-6 w-6 text-blue-500')
				) : (
					<Globe className="h-6 w-6 text-blue-500" />
				)}
				<h3 className="text-xl font-semibold text-gray-900">
					{item.name}
				</h3>
			</div>
		</div>
	);
});

// Memoized Menu Item Component
const MenuItemComponent = memo(function MenuItemComponent({
	category,
}: {
	category: MenuItem;
}) {
	// Determine color scheme based on category
	const getColorScheme = (name: string) => {
		switch (name) {
			case 'SMS Messaging':
				return {
					border: 'border-blue-100',
					hover: 'hover:border-blue-200 hover:bg-blue-50',
					text: 'group-hover:text-blue-600',
					gradient: 'from-blue-50/50',
				};
			case 'Voice Solutions':
				return {
					border: 'border-green-100',
					hover: 'hover:border-green-200 hover:bg-green-50',
					text: 'group-hover:text-green-600',
					gradient: 'from-green-50/50',
				};
			case 'WhatsApp Solutions':
				return {
					border: 'border-purple-100',
					hover: 'hover:border-purple-200 hover:bg-purple-50',
					text: 'group-hover:text-purple-600',
					gradient: 'from-purple-50/50',
				};
			case 'RCS Messaging':
				return {
					border: 'border-orange-100',
					hover: 'hover:border-orange-200 hover:bg-orange-50',
					text: 'group-hover:text-orange-600',
					gradient: 'from-orange-50/50',
				};
			case 'Bulk Email Marketing Services':
				return {
					border: 'border-indigo-100',
					hover: 'hover:border-indigo-200 hover:bg-indigo-50',
					text: 'group-hover:text-indigo-600',
					gradient: 'from-indigo-50/50',
				};
			default:
				return {
					border: 'border-blue-100',
					hover: 'hover:border-blue-200 hover:bg-blue-50',
					text: 'group-hover:text-blue-600',
					gradient: 'from-blue-50/50',
				};
		}
	};

	const colors = getColorScheme(category.name);

	return (
		<div className="w-[200px]">
			<h4 className="mb-1 text-sm text-gray-900">{category.name}</h4>
			{category.submenu?.map((subItem, subIndex) => (
				<Link
					key={subIndex}
					href={subItem.href || '#'}
					className={`group mb-1 block rounded-lg border ${
						subIndex === 0
							? `${colors.border} bg-gradient-to-r ${colors.gradient} to-white`
							: 'border-gray-100 bg-white'
					} p-2.5 transition-all ${colors.hover} hover:shadow-sm`}>
					<div className="flex items-start justify-between gap-2">
						<div className="min-w-0 flex-1">
							<div className="flex items-start gap-2">
								<span
									className={`text-sm text-gray-900 ${colors.text}`}>
									{subItem.name}
								</span>
							</div>
						</div>
						<div className="ml-2 shrink-0 text-gray-400 group-hover:text-gray-600">
							<svg
								className="h-4 w-4"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M9 5l7 7-7 7"
								/>
							</svg>
						</div>
					</div>
				</Link>
			))}
			{!category.submenu?.length && category.href && (
				<Link
					href={category.href}
					className={`group mb-1 block rounded-lg border ${colors.border} bg-gradient-to-r ${colors.gradient} to-white p-2.5 transition-all ${colors.hover} hover:shadow-sm`}>
					<div className="flex items-start justify-between gap-2">
						<div className="min-w-0 flex-1">
							<div className="flex items-start gap-2">
								<span
									className={`text-sm text-gray-900 ${colors.text}`}>
									{category.name}
								</span>
							</div>
						</div>
						<div className="ml-2 shrink-0 text-gray-400 group-hover:text-gray-600">
							<svg
								className="h-4 w-4"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M9 5l7 7-7 7"
								/>
							</svg>
						</div>
					</div>
				</Link>
			)}
		</div>
	);
});

/**
 * Standard menu content with company menu layout
 */
export function StandardMenuContent({ item }: MenuSectionProps) {
	const smsCategory = item.submenu?.find(cat => cat.name === 'SMS Messaging');
	const voiceCategory = item.submenu?.find(
		cat => cat.name === 'Voice Solutions',
	);
	const whatsappCategory = item.submenu?.find(
		cat => cat.name === 'WhatsApp Solutions',
	);
	const rcsCategory = item.submenu?.find(cat => cat.name === 'RCS Messaging');
	const emailCategory = item.submenu?.find(
		cat => cat.name === 'Bulk Email Marketing Services',
	);

	return (
		<div className="menu-content-wrapper w-full">
			<div className="w-full rounded-lg border border-gray-100 bg-white/95 p-3 shadow-lg">
				<MenuHeader item={item} />
				<div className="flex gap-3">
					<div className="w-[200px] space-y-1.5">
						{smsCategory && (
							<MenuItemComponent category={smsCategory} />
						)}
					</div>
					<div className="w-[200px] space-y-1.5">
						{voiceCategory && (
							<MenuItemComponent category={voiceCategory} />
						)}
					</div>
					<div className="w-[200px] space-y-1.5">
						{whatsappCategory && (
							<MenuItemComponent category={whatsappCategory} />
						)}
					</div>
					<div className="w-[200px] space-y-1.5">
						{rcsCategory && (
							<MenuItemComponent category={rcsCategory} />
						)}
						{emailCategory && (
							<MenuItemComponent category={emailCategory} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
