'use client';

import { Menu, X } from 'lucide-react';
import { useEffect, useState } from 'react';

import { Button } from '@/components/ui/button';
import { ContactButton } from '@/components/ui/ContactButton';
import { GetStartedButton } from '@/components/ui/GetStartedButton';
import { Logo } from '@/components/ui/Logo';
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetHeader,
	SheetTrigger,
} from '@/components/ui/sheet';
import { menuData } from '@/lib/menu-data';
import { DesktopNavigation } from '../mega-menu/desktop-navigation';
import { MobileNavigation } from '../mega-menu/mobile-navigation';

export default function Header() {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			setIsScrolled(window.scrollY > 10);
		};
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<header
			className={`sticky top-0 z-50 w-full transition-all duration-300 ${
				isScrolled
					? 'bg-white/90 shadow-sm backdrop-blur-md supports-[backdrop-filter]:bg-white/50'
					: 'bg-transparent'
			}`}>
			<nav
				className="container mx-auto px-4 sm:px-6 lg:px-8"
				aria-label="Main navigation">
				<div className="flex h-16 items-center justify-between">
					{/* Logo section */}
					<div className="-ml-2 -mt-2 flex shrink-0 items-center lg:-ml-4">
						<Logo />
					</div>

					{/* Desktop navigation - centered */}
					<div className="hidden lg:flex lg:flex-1 lg:justify-center">
						<DesktopNavigation menuItems={menuData} />
					</div>

					{/* Action buttons and mobile menu */}
					<div className="flex shrink-0 items-center gap-2 md:gap-4">
						{/* GetStarted button - visible on all screens */}
						<GetStartedButton />

						{/* Contact button - responsive styling handled in component */}
						<ContactButton />

						{/* Mobile menu button - only visible on mobile */}
						<Sheet
							open={mobileMenuOpen}
							onOpenChange={setMobileMenuOpen}>
							<SheetTrigger asChild>
								<Button
									variant="ghost"
									size="icon"
									className="h-10 w-10 lg:hidden"
									aria-label="Open menu">
									<Menu className="h-10 w-10" />
								</Button>
							</SheetTrigger>
							<SheetContent
								side="right"
								className="w-[85vw] max-w-sm border-l p-0 shadow-xl"
								onPointerDownOutside={() =>
									setMobileMenuOpen(false)
								}>
								<SheetHeader className="sticky top-0 z-10 border-b bg-white/95 p-4 backdrop-blur-sm">
									<div className="flex items-center justify-between">
										<Logo />
										<SheetClose asChild>
											<Button
												variant="ghost"
												size="icon"
												className="rounded-full hover:bg-gray-100">
												<X className="h-5 w-5" />
												<span className="sr-only">
													Close menu
												</span>
											</Button>
										</SheetClose>
									</div>
								</SheetHeader>
								<div className="max-h-[calc(100vh-5rem)] overflow-y-auto px-4 py-6">
									<MobileNavigation menuItems={menuData} />
									<div className="mt-8 space-y-4">
										<GetStartedButton
											variant="mobile"
											onClick={() =>
												setMobileMenuOpen(false)
											}
										/>
										<ContactButton
											variant="mobile"
											onClick={() =>
												setMobileMenuOpen(false)
											}
										/>
									</div>
								</div>
							</SheetContent>
						</Sheet>
					</div>
				</div>
			</nav>
		</header>
	);
}
