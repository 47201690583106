import {
	BarChart,
	BookOpen,
	FileText,
	GraduationCap,
	HelpCircle,
	Newspaper,
	TrendingUp,
	Users,
} from 'lucide-react';
import Link from 'next/link';
import type { MenuSectionProps } from '../types';

/**
 * Menu item component for resources
 */
const ResourceMenuItem = ({
	href,
	title,
	description,
	icon,
	isHighlighted = false,
}: {
	href: string;
	title: string;
	description: string;
	icon: React.ReactNode;
	isHighlighted?: boolean;
}) => {
	return (
		<Link
			href={href}
			className={`block rounded-md border ${
				isHighlighted
					? 'border-blue-100 bg-gradient-to-br from-blue-50/50 to-white'
					: 'border-gray-100 bg-white'
			} p-3 transition-all hover:border-blue-100 hover:bg-gradient-to-br hover:from-blue-50/50 hover:to-white hover:shadow-sm`}>
			<div className="flex items-start gap-2">
				<div className="mt-0.5 flex-shrink-0 text-blue-500">{icon}</div>
				<div>
					<div className="text-sm font-medium text-gray-900">
						{title}
					</div>
				</div>
			</div>
		</Link>
	);
};

/**
 * Resources specialized menu - streamlined for our content
 */
export function ResourcesMenu({ item: _item }: MenuSectionProps) {
	return (
		<div className="menu-content-wrapper">
			<div className="rounded-lg border border-gray-100 bg-white/95 p-4 shadow-lg">
				<div className="mb-3 flex items-center justify-between border-b pb-2">
					<div className="flex items-center gap-2">
						<BookOpen className="h-6 w-6 text-blue-500" />
						<h3 className="text-lg text-gray-900">Resources</h3>
					</div>
					<Link
						href="/blog"
						className="rounded bg-blue-50 px-3 py-1.5 text-sm text-blue-600 transition-colors hover:bg-blue-100">
						<div className="flex items-center gap-1">
							<FileText className="h-4 w-4" />
							<span>Latest Articles</span>
						</div>
					</Link>
				</div>

				<div className="grid grid-cols-12 gap-4">
					{/* Main Links */}
					<div className="col-span-7 space-y-2">
						<div className="mb-2 flex items-center gap-2">
							<FileText className="h-5 w-5 text-gray-500" />
							<h4 className="text-base text-gray-900">
								Content Library
							</h4>
						</div>
						<ResourceMenuItem
							href="/blog"
							title="Blog"
							description="Latest news, tips, and industry insights"
							icon={<FileText className="h-4 w-4" />}
							isHighlighted={true}
						/>
						<ResourceMenuItem
							href="/press-releases"
							title="Press Releases"
							description="Company announcements and news coverage"
							icon={<Newspaper className="h-4 w-4" />}
						/>
						<ResourceMenuItem
							href="/case-studies"
							title="Case Studies"
							description="Real-world customer success stories"
							icon={<GraduationCap className="h-4 w-4" />}
						/>
						<ResourceMenuItem
							href="/client-testimonial"
							title="Client Testimonial"
							description="See what our customers say about us"
							icon={<Users className="h-4 w-4" />}
						/>
					</div>

					{/* Featured */}
					<div className="col-span-5">
						<div className="mb-2 flex items-center gap-2">
							<BarChart className="h-5 w-5 text-gray-500" />
							<h4 className="text-base text-gray-900">
								Featured
							</h4>
						</div>
						<div className="space-y-2">
							<Link
								href="/case-studies/enterprise"
								className="flex items-center gap-3 rounded-md border border-gray-100 bg-gradient-to-r from-gray-50 to-white p-3 transition-all hover:border-blue-100 hover:bg-gradient-to-br hover:from-blue-50/50 hover:to-white hover:shadow-sm">
								<div className="flex-shrink-0 text-blue-500">
									<GraduationCap className="h-4 w-4" />
								</div>
								<div className="flex-1">
									<div className="text-sm text-gray-900">
										Enterprise Success
									</div>
									<p className="text-xs text-gray-500">
										How we scaled for large businesses
									</p>
								</div>
							</Link>
							<Link
								href="/blog/chatbots-in-banking"
								className="flex items-center gap-3 rounded-md border border-gray-100 bg-gradient-to-r from-gray-50 to-white p-3 transition-all hover:border-blue-100 hover:bg-gradient-to-br hover:from-blue-50/50 hover:to-white hover:shadow-sm">
								<div className="flex-shrink-0 text-blue-500">
									<TrendingUp className="h-4 w-4" />
								</div>
								<div className="flex-1">
									<div className="text-sm text-gray-900">
										Messaging Trends
									</div>
									<p className="text-xs text-gray-500">
										Latest industry developments
									</p>
								</div>
							</Link>
							<div className="rounded-md border border-blue-100 bg-blue-50/50 p-3">
								<div className="flex items-start gap-2">
									<div className="mt-0.5 flex-shrink-0 text-blue-500">
										<HelpCircle className="h-4 w-4" />
									</div>
									<div>
										<div className="text-sm text-gray-900">
											Need More Information?
										</div>
										<p className="mb-2 text-xs text-gray-600">
											Our team is ready to answer your
											questions
										</p>
										<Link
											href="/contact-us"
											className="text-xs text-blue-600 hover:text-blue-700">
											Contact Us →
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
