import * as LucideIcons from 'lucide-react';
import type React from 'react';
import type { ReactElement } from 'react';

/**
 * Renders an icon from Lucide Icons based on the icon name
 */
export function renderIcon(
	iconName: string,
	className = 'h-5 w-5',
): ReactElement | null {
	// Convert kebab-case to PascalCase
	const formattedIconName =
		iconName.charAt(0).toUpperCase() +
		iconName.slice(1).replace(/-([a-z])/g, g => g[1].toUpperCase());

	// Use type assertion with unknown as intermediate step
	const Icon = (
		LucideIcons as unknown as Record<
			string,
			React.ComponentType<{ className?: string; size?: string | number }>
		>
	)[formattedIconName];

	return Icon ? <Icon className={className} /> : null;
}
