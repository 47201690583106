import {
	Briefcase,
	Building,
	Code,
	FileText,
	GraduationCap,
	Headphones,
	HeartPulse,
	LandPlot,
	Laptop,
	Layers,
	LibrarySquare,
	MapPin,
	Phone,
	PlaneIcon,
	Server,
	Settings,
	ShieldCheck,
	ShoppingCart,
	Truck,
	Users,
	Wallet,
} from 'lucide-react';
import Link from 'next/link';
import { MenuItem } from '@/types/menu';
import type { MenuSectionProps } from '../types';

const MenuItemComponent = ({
	item,
	isHighlighted = false,
	colorScheme = 'blue',
}: {
	item: MenuItem;
	isHighlighted?: boolean;
	colorScheme?: 'blue' | 'green' | 'purple' | 'orange';
}) => {
	const colors = {
		blue: {
			border: 'border-blue-100',
			hover: 'hover:border-blue-200 hover:bg-blue-50',
			text: 'group-hover:text-blue-600',
			gradient: 'from-blue-50/50',
			iconColor: 'text-blue-500',
		},
		green: {
			border: 'border-green-100',
			hover: 'hover:border-green-200 hover:bg-green-50',
			text: 'group-hover:text-green-600',
			gradient: 'from-green-50/50',
			iconColor: 'text-green-500',
		},
		purple: {
			border: 'border-purple-100',
			hover: 'hover:border-purple-200 hover:bg-purple-50',
			text: 'group-hover:text-purple-600',
			gradient: 'from-purple-50/50',
			iconColor: 'text-purple-500',
		},
		orange: {
			border: 'border-orange-100',
			hover: 'hover:border-orange-200 hover:bg-orange-50',
			text: 'group-hover:text-orange-600',
			gradient: 'from-orange-50/50',
			iconColor: 'text-orange-500',
		},
	};

	// Map icon strings to Lucide components
	const getIcon = (iconName?: string) => {
		if (!iconName) return null;

		const iconMap: Record<string, React.ReactNode> = {
			// Industry icons
			building: <Building className={`h-4 w-4 ${scheme.iconColor}`} />,
			healthcare: (
				<HeartPulse className={`h-4 w-4 ${scheme.iconColor}`} />
			),
			bank: <Wallet className={`h-4 w-4 ${scheme.iconColor}`} />,
			utility: <LandPlot className={`h-4 w-4 ${scheme.iconColor}`} />,
			logistics: <Truck className={`h-4 w-4 ${scheme.iconColor}`} />,
			travel: <PlaneIcon className={`h-4 w-4 ${scheme.iconColor}`} />,
			insurance: (
				<LibrarySquare className={`h-4 w-4 ${scheme.iconColor}`} />
			),
			ecommerce: (
				<ShoppingCart className={`h-4 w-4 ${scheme.iconColor}`} />
			),
			education: (
				<GraduationCap className={`h-4 w-4 ${scheme.iconColor}`} />
			),
			political: <MapPin className={`h-4 w-4 ${scheme.iconColor}`} />,

			// Platform icons
			server: <Server className={`h-4 w-4 ${scheme.iconColor}`} />,
			cpaas: <Layers className={`h-4 w-4 ${scheme.iconColor}`} />,
			marketing: <Headphones className={`h-4 w-4 ${scheme.iconColor}`} />,
			integration: <Settings className={`h-4 w-4 ${scheme.iconColor}`} />,
			security: <ShieldCheck className={`h-4 w-4 ${scheme.iconColor}`} />,

			// Business Type icons
			users: <Users className={`h-4 w-4 ${scheme.iconColor}`} />,
			startup: <Laptop className={`h-4 w-4 ${scheme.iconColor}`} />,
			developer: <Code className={`h-4 w-4 ${scheme.iconColor}`} />,
			enterprise: <Building className={`h-4 w-4 ${scheme.iconColor}`} />,

			// Quick Links icons
			shield: <ShieldCheck className={`h-4 w-4 ${scheme.iconColor}`} />,
			file: <FileText className={`h-4 w-4 ${scheme.iconColor}`} />,
			phone: <Phone className={`h-4 w-4 ${scheme.iconColor}`} />,
		};

		return iconMap[iconName] || null;
	};

	const scheme = colors[colorScheme];
	const icon = getIcon(item.icon);

	return (
		<Link
			href={item.href || '#'}
			className={`group mb-1.5 block rounded-md border ${
				isHighlighted
					? `${scheme.border} bg-gradient-to-r ${scheme.gradient} to-white`
					: 'border-gray-100 bg-white'
			} p-2 transition-all ${scheme.hover} hover:shadow-sm`}>
			<div className="flex items-start gap-2">
				{icon && <div className="mt-0.5 flex-shrink-0">{icon}</div>}
				<div className="min-w-0 flex-1">
					<span className={`text-sm text-gray-900 ${scheme.text}`}>
						{item.name}
					</span>
				</div>
			</div>
		</Link>
	);
};

/**
 * Solutions specialized menu
 */
export function SolutionsMenu({ item: _item }: MenuSectionProps) {
	if (!_item?.submenu) {
		console.log('No submenu found in solutions menu');
		return null;
	}

	// Find the categories from submenu
	const byIndustry = _item.submenu.find(cat => cat.name === 'By Industry');
	const ourPlatform = _item.submenu.find(cat => cat.name === 'Our Platform');
	const byBusinessType = _item.submenu.find(
		cat => cat.name === 'By Business Type',
	);

	// Map specific icons based on menu item names
	const getIconForIndustry = (name: string): string => {
		const iconMap: Record<string, string> = {
			Healthcare: 'healthcare',
			'Banking & FinTech': 'bank',
			'Power & Utility': 'utility',
			Logistics: 'logistics',
			'Travel & Holidays': 'travel',
			Insurance: 'insurance',
			'Ecommerce & D2C': 'ecommerce',
			EdTech: 'education',
			Political: 'political',
		};
		return iconMap[name] || 'building';
	};

	const getIconForPlatform = (name: string): string => {
		const iconMap: Record<string, string> = {
			CPaaS: 'cpaas',
			'Marketing Automation': 'marketing',
			Integrations: 'integration',
			Security: 'security',
		};
		return iconMap[name] || 'server';
	};

	const getIconForBusinessType = (name: string): string => {
		const iconMap: Record<string, string> = {
			'Mtalkz for Startup': 'startup',
			'Developer Tools': 'developer',
			'Mtalkz for Enterprises': 'enterprise',
		};
		return iconMap[name] || 'users';
	};

	return (
		<div className="menu-content-wrapper w-full">
			<div className="w-full rounded-lg border border-gray-100 bg-white/95 p-3 shadow-lg">
				<div className="mb-2 flex items-center justify-between border-b pb-2">
					<div className="flex items-center gap-2">
						<Briefcase className="h-5 w-5 text-blue-500" />
						<h3 className="text-base text-gray-900">Solutions</h3>
					</div>
				</div>

				<div className="grid grid-cols-4 gap-4">
					{/* By Industry */}
					<div>
						<h4 className="mb-1.5 text-sm text-gray-900">
							By Industry
						</h4>
						{byIndustry?.submenu?.map((subItem, index) => (
							<MenuItemComponent
								key={subItem.name}
								item={{
									...subItem,
									icon: getIconForIndustry(subItem.name),
								}}
								isHighlighted={index === 0}
								colorScheme="blue"
							/>
						))}
					</div>

					{/* Platform */}
					<div>
						<h4 className="mb-1.5 text-sm text-gray-900">
							Our Platform
						</h4>
						{ourPlatform?.submenu?.map((subItem, index) => (
							<MenuItemComponent
								key={subItem.name}
								item={{
									...subItem,
									icon: getIconForPlatform(subItem.name),
								}}
								isHighlighted={index === 0}
								colorScheme="green"
							/>
						))}
					</div>

					{/* Business Type */}
					<div>
						<h4 className="mb-1.5 text-sm text-gray-900">
							By Business Type
						</h4>
						{byBusinessType?.submenu?.map((subItem, index) => (
							<MenuItemComponent
								key={subItem.name}
								item={{
									...subItem,
									icon: getIconForBusinessType(subItem.name),
								}}
								isHighlighted={index === 0}
								colorScheme="purple"
							/>
						))}
					</div>

					{/* Quick Links */}
					<div>
						<h4 className="mb-1.5 text-sm text-gray-900">
							Quick Links
						</h4>
						<MenuItemComponent
							item={{
								name: 'Security',
								href: '/security',
								description: 'Enterprise-grade security',
								icon: 'shield',
							}}
							isHighlighted={true}
							colorScheme="orange"
						/>
						<MenuItemComponent
							item={{
								name: 'Case Studies',
								href: '/case-studies',
								description: 'Success stories',
								icon: 'file',
							}}
							colorScheme="orange"
						/>
						<MenuItemComponent
							item={{
								name: 'Contact Sales',
								href: '/contact-us',
								description: 'Get solutions',
								icon: 'phone',
							}}
							colorScheme="orange"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
