'use client';

import Script from 'next/script';

export function WhatsAppWidget() {
	return (
		<Script
			src="//in.fw-cdn.com/30051286/36670.js"
			strategy="lazyOnload"
			data-chat="true"
			data-widget-id="33da4138-e044-4a1f-9da2-aa528402a4c1"
			onError={e => {
				console.error('Error loading WhatsApp widget script:', e);
			}}
		/>
	);
}
