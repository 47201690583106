import type { MenuItem } from '@/types/menu';

// Helper function to add icons and descriptions based on item name
function enhanceMenuItem(item: MenuItem): MenuItem {
	const enhanced = { ...item };

	// Add icons based on name
	if (!enhanced.icon) {
		if (/sms|message/i.test(enhanced.name))
			enhanced.icon = 'message-square';
		else if (/whatsapp|chat/i.test(enhanced.name))
			enhanced.icon = 'message-circle';
		else if (/voice|call|ivr|dial/i.test(enhanced.name))
			enhanced.icon = 'phone';
		else if (/email/i.test(enhanced.name)) enhanced.icon = 'mail';
		else if (/rcs/i.test(enhanced.name)) enhanced.icon = 'message-square';
		else if (/developer|api/i.test(enhanced.name)) enhanced.icon = 'code';
		else if (/pricing/i.test(enhanced.name)) enhanced.icon = 'credit-card';
		else if (/blog|press|case|testimonial/i.test(enhanced.name))
			enhanced.icon = 'file-text';
		else if (/career|about|contact|partner/i.test(enhanced.name))
			enhanced.icon = 'users';
		else if (/healthcare/i.test(enhanced.name))
			enhanced.icon = 'heart-pulse';
		else if (/banking|fintech/i.test(enhanced.name))
			enhanced.icon = 'landmark';
		else if (/power|utility/i.test(enhanced.name)) enhanced.icon = 'zap';
		else if (/logistics/i.test(enhanced.name)) enhanced.icon = 'truck';
		else if (/travel/i.test(enhanced.name)) enhanced.icon = 'plane';
		else if (/insurance/i.test(enhanced.name)) enhanced.icon = 'shield';
		else if (/ecommerce/i.test(enhanced.name))
			enhanced.icon = 'shopping-cart';
		else if (/edtech/i.test(enhanced.name))
			enhanced.icon = 'graduation-cap';
		else if (/political/i.test(enhanced.name)) enhanced.icon = 'landmark';
		else if (/platform|cpaas/i.test(enhanced.name))
			enhanced.icon = 'layers';
		else if (/marketing/i.test(enhanced.name)) enhanced.icon = 'megaphone';
		else if (/integration/i.test(enhanced.name)) enhanced.icon = 'plug';
		else if (/security/i.test(enhanced.name)) enhanced.icon = 'shield';
		else if (/startup/i.test(enhanced.name)) enhanced.icon = 'rocket';
		else if (/enterprise/i.test(enhanced.name)) enhanced.icon = 'building';
	}

	// Add descriptions for top-level categories if they don't have one
	if (!enhanced.description && !enhanced.submenu) {
		if (/sms/i.test(enhanced.name)) {
			enhanced.description = 'Send and receive text messages globally';
		} else if (/whatsapp/i.test(enhanced.name)) {
			enhanced.description =
				'Engage customers on WhatsApp Business Platform';
		} else if (/voice/i.test(enhanced.name)) {
			enhanced.description =
				'Make and receive calls with programmable voice';
		} else if (/email/i.test(enhanced.name)) {
			enhanced.description =
				'Send transactional and marketing emails at scale';
		}
	}

	// Process submenu items recursively
	if (enhanced.submenu) {
		enhanced.submenu = enhanced.submenu.map(subItem =>
			enhanceMenuItem(subItem),
		);
	}

	return enhanced;
}

// Update the originalData array to add icons to Products and Solution menu items
const originalData: MenuItem[] = [
	{
		name: 'Products',
		icon: 'package', // Added icon for Products
		submenu: [
			{
				name: 'SMS Messaging',
				description: 'Send and receive text messages globally',
				icon: 'message-square',
				submenu: [
					{
						name: 'Bulk SMS Service',
						href: '/bulk-sms-services',
						description:
							'Send messages to multiple recipients at once',
					},
					{
						name: 'OTP Authenticator',
						href: '/otp-authenticator',
						description:
							'Secure verification with one-time passwords',
					},
					{
						name: 'Long Code Service',
						href: '/long-code-sms-service',
						description: 'Use standard phone numbers for messaging',
					},
					{
						name: 'Shortcode',
						href: '/shortcode',
						description:
							'Use memorable 5-6 digit codes for high volume messaging',
					},
					{
						name: 'Email To SMS',
						href: '/send-email-to-sms',
						description:
							'Convert emails to SMS messages automatically',
					},
					{
						name: 'International SMS',
						href: '/international-bulk-sms',
						description: 'Global messaging with local compliance',
					},
				],
			},
			{
				name: 'WhatsApp Solutions',
				description: 'Engage customers on WhatsApp Business Platform',
				icon: 'message-circle',
				submenu: [
					{
						name: 'WhatsApp Business API',
						href: '/whatsapp-business-api',
						description:
							'Official WhatsApp API for business messaging',
					},
					{
						name: 'Conversational AI Chatbot',
						href: '/conversational-ai-chatbot',
						description: 'AI-powered chatbots for WhatsApp',
					},
					{
						name: 'WhatsApp CRM',
						href: '/whatsapp-crm',
						description: 'Integrate WhatsApp with your CRM system',
					},
					{
						name: 'WhatsApp Chatbot',
						href: '/whatsapp-chatbot',
						description: 'Automated customer service on WhatsApp',
					},
				],
			},
			{
				name: 'RCS Messaging',
				href: '/rcs-messaging',
				description:
					'Rich Communication Services for enhanced messaging',
				icon: 'smartphone',
			},
			{
				name: 'Voice Solutions',
				description: 'Make and receive calls with programmable voice',
				icon: 'phone',
				submenu: [
					{
						name: 'Voice Services',
						href: '/voice-sms-services',
						description: 'Programmable voice calling API',
					},
					{
						name: 'IVR System',
						href: '/ivr-system',
						description: 'Interactive Voice Response systems',
					},
					{
						name: 'Voice OTP',
						href: '/voice-otp',
						description:
							'One-time passwords delivered by voice call',
					},
					{
						name: 'Outbound Dialing Service',
						href: '/outbound-dialing-service',
						description: 'Automated outbound calling solutions',
					},
					{
						name: 'Inbound Dialing Service',
						href: '/inbound-dialing-service',
						description: 'Manage incoming calls programmatically',
					},
					{
						name: 'Missed call service',
						href: '/missed-call-service',
						description: 'Capture and respond to missed calls',
					},
					{
						name: 'Number masking',
						href: '/number-masking',
						description:
							'Protect privacy with virtual phone numbers',
					},
				],
			},
			{
				name: 'Bulk Email Marketing Services',
				description: 'Send transactional and marketing emails at scale',
				icon: 'mail',
				submenu: [
					{
						name: 'Email Solution',
						href: '/bulk-email-marketing-services',
						description: 'Complete email marketing platform',
					},
				],
			},
		],
	},
	{
		name: 'Solution',
		icon: 'lightbulb', // Added icon for Solution
		submenu: [
			{
				name: 'By Industry',
				icon: 'briefcase',
				submenu: [
					{
						name: 'Healthcare',
						href: '/healthcare',
						icon: 'heart-pulse',
						description: 'HIPAA-compliant communication solutions',
					},
					{
						name: 'Banking & FinTech',
						href: '/banking-fintech',
						icon: 'landmark',
						description: 'Secure solutions for financial services',
					},
					{
						name: 'Power & Utility',
						href: '/power-utility',
						icon: 'zap',
						description: 'Communication for utility providers',
					},
					{
						name: 'Logistics',
						href: '/logistics',
						icon: 'truck',
						description: 'Streamline delivery communications',
					},
					{
						name: 'Travel & Holidays',
						href: '/travel-holidays',
						icon: 'plane',
						description: 'Enhance travel experiences',
					},
					{
						name: 'Insurance',
						href: '/insurance',
						icon: 'shield',
						description: 'Solutions for insurance providers',
					},
					{
						name: 'Ecommerce & D2C',
						href: '/ecommerce-d2c',
						icon: 'shopping-cart',
						description: 'Engage online shoppers',
					},
					{
						name: 'EdTech',
						href: '/edtech',
						icon: 'graduation-cap',
						description: 'Communication for education technology',
					},
					{
						name: 'Political',
						href: '/political',
						icon: 'landmark',
						description: 'Campaign and constituent engagement',
					},
				],
			},
			{
				name: 'Our Platform',
				icon: 'layers',
				submenu: [
					{
						name: 'CPaaS',
						href: '/cpaas',
						icon: 'layers',
						description: 'Communication Platform as a Service',
					},
					{
						name: 'Marketing Automation',
						href: '/marketing-automation',
						icon: 'megaphone',
						description: 'Automate your marketing campaigns',
					},
					{
						name: 'Integrations',
						href: '/integrations',
						icon: 'plug',
						description: 'Connect with your favorite tools',
					},
					{
						name: 'Security',
						href: '/security',
						icon: 'shield',
						description: 'Enterprise-grade security and compliance',
					},
				],
			},
			{
				name: 'By Business Type',
				icon: 'building',
				submenu: [
					{
						name: 'Mtalkz for Startup',
						href: '/mtalkz-for-startup',
						icon: 'rocket',
						description: 'Affordable solutions for startups',
					},
					{
						name: 'Developer Tools',
						href: '/developer-tools',
						icon: 'code',
						description: 'APIs and SDKs for developers',
					},
					{
						name: 'Mtalkz for Enterprises',
						href: '/mtalkz-for-enterprises',
						icon: 'building',
						description: 'Enterprise-grade communication solutions',
					},
				],
			},
		],
	},
	{
		name: 'Developer Tools',
		icon: 'code',
		submenu: [
			{
				name: 'Voice API For Developer',
				href: '/voice-api-for-developer',
				icon: 'phone',
				description: 'Integrate voice calling into your applications',
			},
			{
				name: 'OTP API For Developer',
				href: '/otp-api-for-developer',
				icon: 'key',
				description: 'Add verification to your applications',
			},
			{
				name: 'SMS API For Developer',
				href: '/sms-api-for-developer',
				icon: 'message-square',
				description: 'Send and receive SMS programmatically',
			},
			{
				name: 'Email API For Developer',
				href: '/email-api-for-developer',
				icon: 'mail',
				description: 'Integrate email functionality into your apps',
			},
		],
	},
	{
		name: 'Pricing',
		icon: 'credit-card',
		submenu: [
			{
				name: 'Bulk SMS Pricing',
				href: '/bulk-sms-pricing',
				icon: 'message-square',
				description: 'Affordable rates for high-volume messaging',
			},
			{
				name: 'Whatsapp Api Pricing',
				href: '/whatsapp-api-pricing',
				icon: 'message-circle',
				description: 'Transparent pricing for WhatsApp Business API',
			},
			{
				name: 'IVR Pricing',
				href: '/ivr-pricing',
				icon: 'phone',
				description: 'Affordable Interactive Voice Response systems',
			},
			{
				name: 'RCS Pricing',
				href: '/rcs-pricing',
				icon: 'smartphone',
				description: 'Pricing for Rich Communication Services',
			},
			{
				name: 'Bulk Email Pricing',
				href: '/bulk-email-pricing',
				icon: 'mail',
				description: 'Cost-effective email marketing solutions',
			},
		],
	},
	{
		name: 'Resources',
		icon: 'file-text',
		submenu: [
			{
				name: 'Blog',
				href: '/blog',
				icon: 'file-text',
				description: 'Latest insights and updates',
			},
			{
				name: 'Press Releases',
				href: '/press-releases',
				icon: 'newspaper',
				description: 'Company news and announcements',
			},
			{
				name: 'Case Studies',
				href: '/case-studies',
				icon: 'clipboard',
				description: 'Success stories from our customers',
			},
			{
				name: 'Client Testimonial',
				href: '/client-testimonial',
				icon: 'quote',
				description: 'What our customers say about us',
			},
		],
	},
	{
		name: 'Company',
		icon: 'building',
		submenu: [
			{
				name: 'Careers',
				href: '/careers',
				icon: 'briefcase',
				description: 'Join our growing team',
			},
			{
				name: 'About Us',
				href: '/about',
				icon: 'users',
				description: 'Learn more about our company',
			},
			{
				name: 'Contact Us',
				href: '/contact-us',
				icon: 'mail',
				description: 'Get in touch with our team',
			},
			{
				name: 'Channel Partners',
				href: '/channel-partners',
				icon: 'handshake',
				description: 'Partner with us to grow your business',
			},
			{
				name: 'Reseller Program',
				href: '/reseller-program',
				icon: 'users',
				description: 'Become a reseller of our services',
			},
		],
	},
];

export const menuData = originalData;

export const defaultLogo = {
	icon: (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			className="text-white">
			<path d="M22 7.7c0-.6-.4-1.2-.8-1.5l-6.3-3.9a1.72 1.72 0 0 0-1.7 0l-10.3 6c-.5.2-.9.8-.9 1.4 0 1.1.9 2 2 2h16a2 2 0 0 0 2-2Z" />
			<path d="M22 16.3c0 .6-.4 1.2-.8 1.5l-6.3 3.9a1.72 1.72 0 0 1-1.7 0l-10.3-6c-.5-.3-.9-.9-.9-1.4 0-1.1.9-2 2-2h16a2 2 0 0 1 2 2Z" />
			<path d="M5 8v6" />
			<path d="M19 8v6" />
			<path d="M5 16.3V8a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8.3" />
		</svg>
	),
	text: 'mtalkz',
	href: '/',
};

export const defaultCta = {
	primary: {
		text: 'Start for free',
		href: '#',
	},
	secondary: {
		text: 'Contact sales',
		href: '#',
	},
};

export const defaultLanguages = {
	current: 'English',
	options: [
		{ label: 'English', value: 'en' },
		{ label: 'Español', value: 'es' },
		{ label: 'Français', value: 'fr' },
		{ label: 'Deutsch', value: 'de' },
		{ label: 'हिन्दी', value: 'hi' },
	],
};
