'use client';

import { motion } from 'framer-motion';
import {
	Facebook,
	Instagram,
	Linkedin,
	Mail,
	MapPin,
	Phone,
	Send,
	Twitter,
	Youtube,
} from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';
import {
	COMPANY_ADDRESS,
	COMPANY_ASSETS,
	COMPANY_INFO,
	CONTACT_INFO,
	FOOTER_LINKS,
	SOCIAL_LINKS,
} from '@/lib/constants';

const iconMap = {
	Facebook,
	Linkedin,
	Twitter,
	Instagram,
	Mail,
	Youtube,
	Send,
};

const FooterSection = ({
	title,
	items,
}: {
	title: string;
	items: { name: string; href: string }[];
}) => (
	<div className="mb-8 md:mb-0">
		<h2 className="mb-4 border-b border-gray-600 pb-2 text-lg font-semibold text-white">
			{title}
		</h2>
		<ul className="space-y-2">
			{items.map(item => (
				<li key={item.name}>
					<Link
						href={item.href}
						className="group flex items-center text-gray-300 transition-colors duration-200 hover:text-white">
						<span className="mr-2 text-blue-400 transition-colors duration-200 group-hover:text-blue-300">
							•
						</span>
						{item.name}
					</Link>
				</li>
			))}
		</ul>
	</div>
);

export default function Footer() {
	return (
		<footer className="relative overflow-hidden bg-gray-900 py-8 text-white">
			<div className="absolute inset-0 bg-[url('/footer-bg-pattern.svg')] opacity-5"></div>
			<div className="container relative z-10 mx-auto px-4">
				<div className="mb-12 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-5">
					<div className="col-span-1 space-y-8 md:col-span-2 lg:col-span-1">
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.5 }}>
							<Image
								src="https://mtalkz.com/wp-content/uploads/2022/08/footer_logo_2.webp"
								alt={COMPANY_INFO.logoAlt}
								width={186}
								height={37}
								className="h-12 w-auto"
							/>
						</motion.div>
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.5, delay: 0.2 }}
							className="space-y-4">
							<p className="flex items-start gap-3 text-gray-300">
								<MapPin className="mt-1 h-5 w-5 flex-shrink-0 text-blue-400" />
								<span>
									{COMPANY_ADDRESS.name}
									{COMPANY_ADDRESS.address.map((line, i) => (
										<Fragment key={i}>
											<br />
											{line}
										</Fragment>
									))}
								</span>
							</p>
							<p className="flex items-center gap-3">
								<Mail className="h-5 w-5 flex-shrink-0 text-blue-400" />
								<a
									href={`mailto:${CONTACT_INFO.email}`}
									className="text-gray-300 transition-colors duration-200 hover:text-white">
									{CONTACT_INFO.email}
								</a>
							</p>
							<p className="flex items-center gap-3">
								<Phone className="h-5 w-5 flex-shrink-0 text-blue-400" />
								<a
									href={`tel:${CONTACT_INFO.phone}`}
									className="text-gray-300 transition-colors duration-200 hover:text-white">
									{CONTACT_INFO.phone}
								</a>
							</p>
						</motion.div>
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.5, delay: 0.4 }}
							className="pt-6">
							<Image
								src={COMPANY_ASSETS.trustSeal}
								alt="Mtalkz Seal of Trust"
								width={300}
								height={87}
								className="w-full max-w-[300px]"
							/>
						</motion.div>
					</div>

					<FooterSection
						title="PRODUCTS"
						items={[
							...FOOTER_LINKS.products,
							...FOOTER_LINKS.businesses,
						]}
					/>
					<FooterSection
						title="SOLUTIONS"
						items={FOOTER_LINKS.solutions}
					/>
					<FooterSection
						title="RESOURCES"
						items={FOOTER_LINKS.resources}
					/>
					<FooterSection
						title="COMPANY"
						items={FOOTER_LINKS.company}
					/>
				</div>

				<motion.div
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.5, delay: 0.8 }}
					className="mt-12 flex flex-col items-center justify-between border-t border-gray-700 pt-8 md:flex-row">
					<p className="mb-4 text-sm text-gray-400 md:mb-0">
						&copy; {new Date().getFullYear()} {COMPANY_INFO.name}.
						All rights reserved.
					</p>
					<div className="flex space-x-4">
						{SOCIAL_LINKS.map(item => {
							const Icon =
								iconMap[item.icon as keyof typeof iconMap];
							return (
								<a
									key={item.name}
									href={item.href}
									className="rounded-full bg-gray-800 p-2 text-gray-400 transition-colors duration-200 hover:bg-gray-700 hover:text-white"
									target="_blank"
									rel="noopener noreferrer"
									aria-label={`Follow us on ${item.name}`}>
									<Icon className="h-5 w-5" />
								</a>
							);
						})}
					</div>
				</motion.div>
			</div>
		</footer>
	);
}
