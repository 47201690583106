'use client';

import Link from 'next/link';
import React, { memo, useState } from 'react';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import { SheetClose } from '@/components/ui/sheet';
import { cn } from '@/lib/utils';
import type { MenuItem } from '@/types/menu';
import type { MobileMenuItemProps, MobileNestedMenuProps } from './types';
import { renderIcon } from './utils';

/**
 * Mobile navigation component
 */
export const MobileNavigation = memo(function MobileNavigation({
	menuItems,
}: {
	menuItems: MenuItem[];
}) {
	return (
		<div className="mobile-menu-wrapper max-h-[calc(100vh-200px)] w-full overflow-y-auto">
			<Accordion type="single" collapsible className="w-full">
				{menuItems.map((item, index) => (
					<AccordionItem
						key={index}
						value={item.name.toLowerCase().replace(/\s+/g, '-')}
						className="border-b">
						<AccordionTrigger className="group rounded-md px-2 py-4 text-base font-medium transition-colors">
							<div className="flex items-center gap-2">
								{item.icon &&
									renderIcon(
										item.icon,
										'h-5 w-5 text-gray-500 group-data-[state=open]:text-blue-500',
									)}
								<span className="group-data-[state=open]:text-blue-600">
									{item.name}
								</span>
							</div>
						</AccordionTrigger>
						<AccordionContent className="mobile-accordion-content">
							{item.submenu ? (
								<MobileNestedMenu submenus={item.submenu} />
							) : (
								item.href && (
									<SheetClose asChild>
										<Link
											href={item.href}
											className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
											{item.description || item.name}
										</Link>
									</SheetClose>
								)
							)}
						</AccordionContent>
					</AccordionItem>
				))}
			</Accordion>
		</div>
	);
});

/**
 * Mobile nested menu component
 */
export function MobileNestedMenu({ submenus }: MobileNestedMenuProps) {
	const [_isMenuOpen, _setIsMenuOpen] = useState(true);

	return (
		<Accordion type="single" collapsible className="pl-2">
			{submenus.map((subItem, subIndex) => (
				<AccordionItem
					key={subIndex}
					value={subItem.name.toLowerCase().replace(/\s+/g, '-')}
					className="border-b last:border-0">
					<AccordionTrigger className="group rounded-md px-2 py-3 text-sm font-medium transition-colors hover:bg-gray-50">
						<div className="flex items-center gap-2">
							{subItem.icon &&
								renderIcon(
									subItem.icon,
									'h-4 w-4 text-gray-500 group-data-[state=open]:text-blue-500',
								)}
							<span className="group-data-[state=open]:text-blue-600">
								{subItem.name}
							</span>
						</div>
					</AccordionTrigger>
					<AccordionContent>
						{subItem.submenu ? (
							<ul className="space-y-1 pl-2">
								{subItem.submenu.map(
									(nestedItem, nestedIndex) => (
										<MobileMenuItem
											key={nestedIndex}
											item={nestedItem}
											isNew={
												nestedIndex === 0 &&
												subIndex === 0
											}
										/>
									),
								)}
							</ul>
						) : subItem.href ? (
							<SheetClose asChild>
								<Link
									href={subItem.href}
									className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
									{subItem.description || subItem.name}
								</Link>
							</SheetClose>
						) : null}
					</AccordionContent>
				</AccordionItem>
			))}
		</Accordion>
	);
}

/**
 * Mobile menu item component
 */
export function MobileMenuItem({
	item,
	className,
	isNew,
}: MobileMenuItemProps) {
	if (!item.href) return null;

	return (
		<li
			className={cn(
				'rounded-md transition-colors hover:bg-gray-50 active:bg-gray-100',
				className,
			)}>
			<SheetClose asChild>
				<Link
					href={item.href}
					className="mobile-menu-link flex items-start gap-2 p-3">
					<div className="mt-0.5 flex-shrink-0">
						{item.icon &&
							renderIcon(item.icon, 'h-4 w-4 text-blue-500')}
					</div>
					<div className="min-w-0 flex-1">
						<div className="flex items-center gap-2">
							<span className="text-sm font-medium">
								{item.name}
							</span>
							{isNew && (
								<span className="inline-flex items-center rounded-full bg-gradient-to-r from-green-400 to-green-500 px-2 py-0.5 text-xs font-medium text-white shadow-sm">
									New
								</span>
							)}
						</div>
						{item.description && (
							<p className="mt-1 line-clamp-2 text-xs text-gray-500">
								{item.description}
							</p>
						)}
					</div>
					{item.badge && (
						<span className="self-start rounded-full bg-blue-50 px-2 py-0.5 text-xs font-medium text-blue-600">
							{item.badge}
						</span>
					)}
				</Link>
			</SheetClose>
		</li>
	);
}
