import { Phone } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { CONTACT_INFO } from '@/lib/constants';

interface ContactButtonProps {
	variant?: 'mobile' | 'desktop';
	onClick?: () => void;
}

export function ContactButton({
	variant = 'desktop',
	onClick,
}: ContactButtonProps) {
	return (
		<Button
			className={`items-center ${
				variant === 'mobile'
					? 'w-full md:hidden'
					: 'h-auto p-1.5 text-xs hover:bg-blue-700 md:inline-flex md:h-auto md:p-2 md:text-base'
			} bg-blue-600 transition-colors duration-200`}
			asChild
			onClick={onClick}>
			<a href={`tel:${CONTACT_INFO.phone}`}>
				<Phone className={`h-3 w-3 md:h-4 md:w-4`} />
				<span>{CONTACT_INFO.phone}</span>
			</a>
		</Button>
	);
}
